{
  "name": "partnerprofile",
  "version": "8.0.0",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "build": "ng lint & ng build",
    "test": "ng test",
    "lint": "ng lint",
    "e2e": "ng e2e",
    "test-headless": "ng test --watch=false --browsers=ChromeHeadless",
    "build-dev": "ng build --progress=false --configuration=dev --aot --build-optimizer --outputPath=../api/partnerprofile.api/wwwroot",
    "refreshVSToken": "vsts-npm-auth -config .npmrc",
    "build-analyzer": "ng build --configuration prod --stats-json && npx webpack-bundle-analyzer ./dist/partnerprofile/stats.json",
    "prepare": "husky install"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.2.6",
    "@angular/cdk": "^18.2.6",
    "@angular/common": "^18.2.6",
    "@angular/compiler": "^18.2.6",
    "@angular/core": "^18.2.6",
    "@angular/forms": "^18.2.6",
    "@angular/localize": "^18.2.6",
    "@angular/platform-browser": "^18.2.6",
    "@angular/platform-browser-dynamic": "^18.2.6",
    "@angular/router": "^18.2.6",
    "@datadog/browser-logs": "^5.23.0",
    "@datadog/browser-rum": "^5.5.0",
    "@datadog/datadog-ci": "^2.41.0",
    "@datorama/akita": "^6.2.0",
    "@ng-bootstrap/ng-bootstrap": "^17.0.1",
    "@okta/okta-signin-widget": "^7.19.3",
    "@popperjs/core": "^2.11.8",
    "@turf/helpers": "^6.5.0",
    "@types/inputmask": "^5.0.0",
    "bootstrap": "^5.2.0",
    "chart.js": "^3.0.0",
    "chartjs-plugin-datalabels": "^2.2.0",
    "classlist.js": "^1.1.20150312",
    "hammerjs": "^2.0.8",
    "html2canvas": "^1.0.0-rc.5",
    "inputmask": "^5.0.5",
    "jquery": "^3.5.1",
    "jspdf": "^2.3.1",
    "launchdarkly-js-client-sdk": "^3.4.0",
    "mapbox-gl": "3.2.0",
    "ng2-pdfjs-viewer": "^18.0.0",
    "ngx-infinite-scroll": "^18.0.0",
    "ngx-timeago": "^3.0.0",
    "popper.js": "^1.16.1",
    "primeicons": "^7.0.0",
    "primeng": "^17.18.11",
    "pubnub": "^4.33.1",
    "quill": "^2.0.2",
    "rxjs": "^7.8.1",
    "sustainment-component": "npm:@sustainmenttech/sustainment-component@7.18.0-dev.23",
    "sustainment-models": "npm:@sustainmenttech/sustainment-models@2.8.36",
    "tslib": "^2.0.0",
    "zone.js": "~0.14.8"
  },
  "devDependencies": {
    "@angular-devkit/architect": "^0.1802.6",
    "@angular-devkit/build-angular": "^18.2.6",
    "@angular-eslint/builder": "^18.3.1",
    "@angular-eslint/eslint-plugin": "^18.3.1",
    "@angular-eslint/eslint-plugin-template": "^16.3.1",
    "@angular-eslint/schematics": "^18.3.1",
    "@angular-eslint/template-parser": "^18.3.1",
    "@angular/cli": "^18.2.6",
    "@angular/compiler-cli": "^18.2.7",
    "@eslint/js": "^9.7.0",
    "@turf/bbox": "^6.5.0",
    "@turf/bbox-polygon": "^6.5.0",
    "@turf/boolean-contains": "^6.5.0",
    "@turf/circle": "^6.5.0",
    "@turf/transform-scale": "^6.5.0",
    "@turf/turf": "^6.5.0",
    "@types/eslint__js": "^8.42.3",
    "@types/jasmine": "~3.6.0",
    "@types/jasminewd2": "~2.0.3",
    "@types/mapbox-gl": "2.3.2",
    "@types/pubnub": "^4.29.4",
    "@typescript-eslint/eslint-plugin": "7.17.0",
    "@typescript-eslint/parser": "7.17.0",
    "codelyzer": "^6.0.0",
    "eslint": "^8.57.0",
    "eslint-config-prettier": "^9.1.0",
    "husky": "^7.0.4",
    "jasmine-core": "~3.8.0",
    "jasmine-spec-reporter": "~5.0.0",
    "karma": "~6.3.4",
    "karma-chrome-launcher": "~3.1.0",
    "karma-coverage-istanbul-reporter": "~3.0.2",
    "karma-jasmine": "~4.0.0",
    "karma-jasmine-html-reporter": "^1.7.0",
    "lint-staged": "13.0.3",
    "prettier": "^3.3.3",
    "protractor": "~7.0.0",
    "ts-node": "10.9.1",
    "typescript": "^5.4.5",
    "typescript-eslint": "^8.8.0",
    "webpack": "~5.88.2",
    "webpack-bundle-analyzer": "^4.5.0"
  },
  "husky": {
    "hooks": {
      "pre-commit": "lint-staged"
    }
  },
  "lint-staged": {
    "*.ts": "eslint --cache --fix",
    "*.{ts,scss,html}": "prettier --write --fix"
  }
}
